import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"

export const getLangsMenu = path => {
  const langKey = getCurrentLangKey(["en_us", "ka"], "en_us", path)
  const homeLink = langKey === "en_us" ? "/" : `/${langKey}/`
  const langsMenu = getLangs(
    ["en_us", "ka"],
    langKey,
    getUrlForLang(homeLink, path)
  )

  const ka = langsMenu.filter(el => el.langKey === "ka")[0]
  const en = langsMenu.filter(el => el.langKey === "en_us")[0]

  return { ka, en }
}
