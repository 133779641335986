import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { getLangsMenu } from "../utils/locale"
import styles from "./styles.module.scss"

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "en-US" } }
      sort: { order: DESC, fields: createdAt }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        summary
        slug
        contentful_id
      }
    }
    allContentfulGeneralConfig {
      nodes {
        logo {
          file {
            url
          }
        }
      }
    }
  }
`

export default function Blogs({ data, location, pageContext }) {
  const general = data.allContentfulGeneralConfig.nodes[0]
  const posts = data.allContentfulBlogPost.nodes
  const { ka } = getLangsMenu(location.pathname)
  const linkToKa = ka.link
  const linkToEn = ka.link.slice(3)
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <div className={styles.blog}>
      <nav>
        <div>
          <Link to="/">
            <img src={general.logo.file.url} alt="logo" />
          </Link>
        </div>
        <ul>
          <li>
            <Link to={linkToEn}>en</Link>
          </li>
          <li>
            <Link to={linkToKa}>ge</Link>
          </li>
        </ul>
      </nav>
      <div className={styles.container}>
        {posts.map((post, i) => (
          <div className={styles.blog__post} key={i}>
            <Link to={`/${post.slug || post.contentful_id}`}>
              <h2>{post.title}</h2>
            </Link>
            <p>{post.summary}</p>
          </div>
        ))}
        <div className={styles.breadcrumbs}>
          <div>
            {previousPagePath && (
              <Link to={previousPagePath}>
                <button>← Newer Posts</button>
              </Link>
            )}
          </div>
          <div>
            {nextPagePath && (
              <Link to={nextPagePath}>
                <button>Older Posts →</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
